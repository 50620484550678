import $ from 'jquery';
window.jQuery = $; window.$ = $;
import uikit from 'uikit';
import uikitLightbox from '../../../node_modules/uikit/src/js/components/lightbox.js';
//import select2 from '../../../node_modules/select2/dist/js/select2.full.js';
import select2 from 'select2';
import LazyLoad from '../../../node_modules/vanilla-lazyload/dist/lazyload.amd.min.js';
import tooltipster from 'tooltipster';
const allModules = require('./assets/scripts/initialize');
import './assets/styles/main.scss';

$(document).ready(() => {
	allModules.init('serpchecker');
});
