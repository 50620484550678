const Locations = require("mangools-commons/lib/constants/Locations")
	.BASIC_LOCATIONS;
const Platforms = require("mangools-commons/lib/constants/Platforms").default;

module.exports = {
	/* eslint-disable prefer-arrow-callback */
	searchForm() {
		// Location select
		$(function addClassOnReady() {
			if ($(".mg-searchform").length > 0) {
				let prevData = [];

				$(".location-select").select2({
					data: Locations.map((item) => ({
						id: item.id,
						text: item.label,
						code: item.code,
						type: item.type,
					})),
					ajax: {
						data(params) {
							return {
								query: params.term,
							};
						},
						dataType: "json",
						delay: 150,
						processResults(data, params) {
							try {
								const defaultLocations = Locations.map((item) => ({
									id: item.id,
									text: item.label,
									code: item.code,
									type: item.type,
								}));

								defaultLocations.push({
                                    type: 'more'
                                });

								if (params.term === undefined) {
									if (prevData.length > 0) {
										return { results: prevData };
									} else {
										return { results: defaultLocations };
									}
								} else if (params.term === "") {
									prevData = [];
									return { results: defaultLocations };
								} else {
									const newData = data.reduce((acc, item) => {
										const newAcc = acc.slice();
										const groupIndex = newAcc.findIndex(
											(group) => group.text === item.country_code
										);

										if (groupIndex === -1) {
											newAcc.push({
												text: item.country_code,
												children: [
													{
														id: parseInt(item._id, 10), // eslint-disable-line no-underscore-dangle
														text: item.label,
														code: item.code,
														type: item.target_type,
													},
												],
											});

											return newAcc;
										} else {
											const group = Object.assign({}, newAcc[groupIndex]);

											group.children.push({
												id: parseInt(item._id, 10), // eslint-disable-line no-underscore-dangle
												text: item.label,
												code: item.code,
												type: item.target_type,
											});

											return [].concat(
												newAcc.slice(0, groupIndex),
												group,
												newAcc.slice(groupIndex + 1)
											);
										}
									}, []);

									// Save prevData
									prevData = newData;

									return {
										results: newData,
									};
								}
							} catch (e) {
								console.error(e);
							}
						},
						url: `${process.env.API_HOST}/v3/kwfinder/locations`,
					},
					escapeMarkup(markup) {
						return markup; // let our custom formatter work
					},
					minimumInputLength: 0,
					templateResult(location) {
						if (location.children) {
							return `
								<span>${location.text}</span>
								`;
						} else if (location.type === 'more') {
							return `
								<span class="uk-flex uk-flex-space-between">
									<span class="uk-flex-item-1 uk-flex">
										+52k other places, just use search
									</span>
								</span>
							`;
						} else {
							return `
								<span class="uk-flex uk-flex-space-between">
										<span class="uk-flex-item-1 uk-flex">
												<span class="flag ${location.code}"></span>
												${location.text}
										</span>
										<span class="uk-hidden-small color-grey font-10">
												${location.type || ""}
										</span>
								</span>
							`;
						}
					},
					language: {
						errorLoading() {
							return "Searching...";
						},
					},
				});

				// Platform select
				$(".platform-select").select2({
					minimumResultsForSearch: Infinity,
					data: Platforms.map((item) => ({
						id: item.id,
						text: item.label,
					})),
				});
			}
		});

		$(".location-select")
			.select2()
			.on("select2:open", function (e) {
				$(".select2-search__field").attr(
					"placeholder",
					"Search city, district, country"
				);
			});

		$(".mg-searchform").submit(function handleSearchFormSubmit() {
			const keywordInput = $(this).find("input[name='keyword']");
			const trimmedKeyword = keywordInput.val().trim();
			keywordInput.val(trimmedKeyword);
			return true;
		});

		/* Handle source switching */

		$('.mg-searchform [name="source_id"]').val(0);

		$("[data-source-switcher] li").on("click", function () {
			var i = $(this).index();

			if (i == 0) {
				$("[data-source-switcher] li:nth-of-type(1)").addClass("uk-active");
				$("[data-source-switcher] li:nth-of-type(2)").removeClass("uk-active");

				$(".mg-searchform-options").removeClass(
					"uk-width-medium-1-2 uk-width-xlarge-4-10"
				);
				$(".mg-searchform-options").addClass("uk-width-medium-2-3");
				$(".mg-searchform-options")
					.prev()
					.removeClass("uk-width-medium-1-2 uk-width-xlarge-6-10 uk-width-1-1");
				$(".mg-searchform-options").prev().addClass("uk-width-medium-1-3");
				$(".mg-searchform-select:nth-of-type(2)").removeClass("uk-hidden");
				$(".mg-searchform-select:nth-of-type(1)").removeClass("uk-width-1-1");
				$(".mg-searchform-select:nth-of-type(1)").addClass(
					"uk-width-medium-1-2"
				);
				$(".mg-searchform-s:nth-of-type(1)").removeClass("uk-hidden");
				$(".mg-searchform-s:nth-of-type(2)").addClass("uk-hidden");
				$(".location-smr-select").next().addClass("uk-hidden");
				$(".location-select").next().removeClass("uk-hidden");
				$(".location-select").attr("disabled", false);
				$(".location-smr-select").attr("disabled", true);
			}

			if (i == 1) {
				$("[data-source-switcher] li:nth-of-type(2)").addClass("uk-active");
				$("[data-source-switcher] li:nth-of-type(1)").removeClass("uk-active");

				$(".mg-searchform-options").addClass(
					"uk-width-medium-1-2 uk-width-xlarge-4-10"
				);
				$(".mg-searchform-options").removeClass("uk-width-medium-2-3");
				$(".mg-searchform-options")
					.prev()
					.addClass("uk-width-medium-1-2 uk-width-xlarge-6-10 uk-width-1-1");
				$(".mg-searchform-options").prev().removeClass("uk-width-medium-1-3");
				$(".mg-searchform-select:nth-of-type(2)").addClass("uk-hidden");
				$(".mg-searchform-select:nth-of-type(1)").addClass("uk-width-1-1");
				$(".mg-searchform-select:nth-of-type(1)").removeClass(
					"uk-width-medium-1-2"
				);
				$(".mg-searchform-s:nth-of-type(2)").removeClass("uk-hidden");
				$(".mg-searchform-s:nth-of-type(1)").addClass("uk-hidden");
				$(".location-smr-select").next().removeClass("uk-hidden");
				$(".location-select").next().addClass("uk-hidden");
				$(".location-select").attr("disabled", true);
				$(".location-smr-select").attr("disabled", false);
			}

			$('.mg-searchform [name="source_id"]').val(i);
		});
	},

	clickMenuLink() {
		$(".mg-header a[href*='#']").click(function handleMenuLinkClick() {
			$("#mg-main-nav").removeClass("is-visible");
			$("body").css("overflow-y", "auto");
			$("#mg-main-nav").attr("aria-hidden", true);
			$('[data-menu-toggle="close"]').addClass("is-hidden");
			$("#mobile-menu-overlay").removeClass("uk-active");
		});
	},

	menuToggle() {
		const wWidth = $(window).width();

		if (wWidth <= 1220) {
			$("#mg-main-nav").attr("aria-hidden", true);
		}

		$("[data-menu-toggle], #mobile-menu-overlay").click(
			function handleMenuLinkClick() {
				$("#mg-main-nav").toggleClass("is-visible");
				if ($("#mg-main-nav").hasClass("is-visible")) {
					$("body").css("overflow-y", "hidden");
					$("#mg-main-nav").attr("aria-hidden", false);
					$('[data-menu-toggle="close"]').removeClass("is-hidden");
					$("#mobile-menu-overlay").addClass("uk-active");
					if (myLazyLoad) {
						myLazyLoad.update();
					}
				} else {
					$("body").css("overflow-y", "auto");
					$("#mg-main-nav").attr("aria-hidden", true);
					$('[data-menu-toggle="close"]').addClass("is-hidden");
					$("#mobile-menu-overlay").removeClass("uk-active");
				}
			}
		);

		$(window).on("resize", function (event) {
			if ($("#mg-main-nav").hasClass("is-visible")) {
				$("body").css("overflow-y", "hidden");
				$("#mg-main-nav").attr("aria-hidden", false);
			} else {
				$("body").css("overflow-y", "auto");
				$("#mg-main-nav").attr("aria-hidden", true);
			}
		});
	},

	stickyHeader() {
		if ($("#sticky-header").length) {
			if ($(document).scrollTop() >= 100) {
				$("#sticky-header").addClass("is-scrolled");
			} else {
				$("#sticky-header").removeClass("is-scrolled");
			}
		}
	},

	scrollTopBtn() {
		if ($(".mg-btn.is-scroll-top").length) {
			if ($(document).scrollTop() >= 200) {
				$(".mg-btn.is-scroll-top").addClass("uk-animation-slide-bottom");
			} else {
				$(".mg-btn.is-scroll-top").removeClass("uk-animation-slide-bottom");
			}
		}
	},

	scaleScreenshot() {
		if ($(".mg-screenshot").length) {
			if ($(document).scrollTop() >= $(".mg-screenshot").offset().top - 400) {
				$(".mg-screenshot").addClass("is-scaled");
			} else {
				$(".mg-screenshot").removeClass("is-scaled");
			}
		}
	},

	featureVideo() {
		$(".mg-feature-video-header").on("click", function () {
			$(this).find("svg").toggleClass("uk-hidden");
			$(".mg-feature-video-overlay").toggleClass("uk-hidden");
			$(".mg-feature-video-inner").toggleClass("is-expanded");
		});
	},

	featureVideoScroll() {
		const wWidth = $(window).width();

		if ($(".mg-feature-video").length) {
			if (wWidth > 768) {
				if (
					$(document).scrollTop() >=
					$(".mg-feature-video-container").offset().top + 451 - 200
				) {
					$(".mg-feature-video-inner").addClass("is-fixed fade-in-right-scale");
				} else {
					$(".mg-feature-video-inner").removeClass(
						"is-fixed fade-in-right-scale"
					);
				}
			}
		}
	},


	/* Ugly fix that will autoplay youtube video after the lightbox open */

	/*
	youtubeVideo() {
		$("a[data-uk-lightbox]").click(function () {
			setTimeout(function () {
				$(".uk-modal iframe")[0].src +=
					"?autoplay=1&list=PLXennlKNpbqg4P9DzbsLddKv0euEPYtQY";
			}, 1000);
		});
	}, */

	initLazyload() {
		myLazyLoad = new LazyLoad({
			elements_selector: "[data-bg], [data-src]",
			threshold: 400,
		});
	},

	initTooltipster() {
		$(".mg-tooltip").tooltipster();
	},

	disableInitHide() {
		$(".initial-hide").removeClass("initial-hide");
		$(".initial-hide-mobile").removeClass("initial-hide-mobile");
		$("body").addClass("is-loaded");
	},

	init() {
		this.throttledScrollTopBtn = throttle(this.scrollTopBtn, 250);
		window.addEventListener("scroll", this.throttledScrollTopBtn);

		this.throttledStickyHeader = throttle(this.stickyHeader, 250);
		window.addEventListener("scroll", this.throttledStickyHeader);

		this.throttledScaleScreenshot = throttle(this.scaleScreenshot, 250);
		window.addEventListener("scroll", this.throttledScaleScreenshot);

		this.throttledFeatureVideoScroll = throttle(this.featureVideoScroll, 250);
		window.addEventListener("scroll", this.throttledFeatureVideoScroll);

		/* Setting init scroll position when window is scrolled on load */
		this.stickyHeader();

		this.featureVideo();
		this.disableInitHide();
		this.clickMenuLink();
		this.menuToggle();
		this.searchForm();
		this.initLazyload();
		this.initTooltipster();
		// this.youtubeVideo();
	},
	/* eslint-enable prefer-arrow-callback */
};
